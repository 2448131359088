<template>
    <div class="fill-height pa-4">
        <v-tabs color="primary accent-4" right>
            <v-tab>English</v-tab>
            <v-tab>Spanish</v-tab>

            <v-tab-item v-for="n in 2" :key="n">
                <v-container fluid>
                    <vue-editor
                        v-if="emailBody.length > 0"
                        @focus="onEditorFocus"
                        @blur="onEditorBlur"
                        :editor-toolbar="customToolbar"
                        v-model="emailBody[n - 1].body"
                    />
                </v-container>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    name: 'EmailBodySettings',
    props: {
        setting: Object,
        emailBody: Array,
    },
    components: {
        VueEditor,
    },
    computed: {
        ...mapGetters(['getEmailBody']),
    },
    data() {
        return {
            terms: '',
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
        }
    },
    updated() {
        this.setEmailBody(this.emailBody)
    },
    mounted() {
        if (this.emailBody.length > 0) {
            this.emailBody.forEach(x => {
                if (!x.body) {
                    x.body = ''
                }
            })
        }
    },
    methods: {
        ...mapMutations(['setEmailBody']),
        ...mapActions(['quoteChangesAction']),
        onEditorFocus() {
            this.quoteChangesAction(false)
        },
        onEditorBlur() {
            this.quoteChangesAction(true)
        },
    },
}
</script>

<style scoped>
.quillWrapper {
    height: 100%;
}
.quillWrapper >>> .ql-toolbar {
    border: none;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.quillWrapper >>> .ql-container {
    height: calc(100% - 48px);
    border: none;
}
</style>
